import { Injectable } from '@angular/core';
import { RepositoryService } from './Repository.service';
import { EndpointService } from './Endpoint.service';
import { SessionService } from './Session.service';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private repository: RepositoryService, private session: SessionService) { }

    public Authenticate(username: string, password: string) {

        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            username: username,
            password: Md5.hashStr(password)
        };

        return this.repository.post(EndpointService.Authenticate, payload);
    }
}
