export class ErrorMessage {

    public Summary: string;
    public Details: string;
    public HideBackLink: boolean;
    public StartAgain: boolean;
    public IsMessage: boolean;    

    constructor(message?: any, startAgain?: boolean, isMessage?: boolean) {

        if (message) {

            if (message.Summary) {
                this.Summary = message.Summary;
            }
            else {
                this.Summary = message.Details;
            }

            if (message.Details) {
                this.Details = message.Details;
            }
            else {
                this.Details = message.Summary;
            }

            this.StartAgain = startAgain;
            this.IsMessage = isMessage;
        }
    }
}