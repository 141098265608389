import { Component, OnInit, Input, Output, ElementRef, ViewChild, NgZone } from '@angular/core';
import { InternalSection } from '../../Models/InternalSection.model';
import { HelpersService } from '../../Services/helpers.service';
import { DriverOnboardingService } from '../../Services/DriverOnboarding.service';
import { ErrorMessage } from '../../Models/Error.model';
import { Section } from '../../Models/Section.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

    @ViewChild("txtInput") txtInput: ElementRef;
    @ViewChild("dateInput") dateInput: ElementRef;
    @ViewChild("proceedBtn") proceedBtn: ElementRef;
    @ViewChild("driverImage") driverImage: ElementRef;
    @ViewChild("docImage") docImage: ElementRef;

    @Input('_Sections') set _Sections(value: Section[]) {

        this.Sections = value;
        this.TotalSections = this.Sections ? this.Sections.length : 0;

        var inProgress = this.applicationInProgress();

        if (inProgress) {
            this.GetApplicationStatus(!this.IsFirstSection && !inProgress);
        }

    };

    @Input('_IsFirstSection') set _IsFirstSection(value: boolean) {

        this.IsFirstSection = value;

        var inProgress = this.applicationInProgress();

        if (this.IsFirstSection && !inProgress) {

            this.SelectFirstSection();
        }
        else if (!this.IsFirstSection && !inProgress) {

            this.GetApplicationStatus(!this.IsFirstSection && !inProgress);
        }
    };

    public Sections: Section[]
    public CurrentIndex = -1;
    public IsLastSection = false;
    public IsFirstSection = false;
    public IsSecondLastStep = false;
    public TotalSections = 0;
    public ProgressPercentage = 0;
    public Section = new InternalSection();
    public ErrorMessage = null;
    public IsKeyboardOpen: boolean = false;
    public IsiOS: boolean = false;
    public IsFocused: boolean = false;
    public MaxImageWidth = 720;
    public TotalFileSize = 0;
    public BackgroundColours = ["#ff0000" /* error */, "#EFA136", "#F47941", "#B22E40", "#05858C", "#323742" /* message */, "#50BC89", "#33B6AE", "#2A5AA6" /* login */, "#FFD13A"];

    constructor(
        private driverOnbordingService: DriverOnboardingService,
        public helperService: HelpersService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private appComponent: AppComponent,
        private ngZone: NgZone,
        private deviceService: DeviceDetectorService) {

        //this.deviceService.isMobile();
        //this.deviceService.device == "iPhone";
        this.IsiOS = this.deviceService.os == "iOS";
        
        this.SetupKeyboardOpenEvent();
    }

    ngOnInit() {
        this.TotalFileSize = 0;
     }

    public SubmitApplication() {

        let exceedsTotalSize = this.TotalFileSize / (1024 * 1024) > 16;
        this.spinner.show();

        this.driverOnbordingService.SubmitApplication().subscribe((result: any) => {

            this.spinner.hide();

            if (result.SubmitApplicationResult.Info.Status == "SUCCESS") {

                this.GetApplicationStatus();
            }
            else {

                if (result.SubmitApplicationResult.Info.Messages.length > 0) {

                    let errorMessage = '';

                    this.IsSecondLastStep = true;
                    if (exceedsTotalSize) {

                        this.translate.get("TotalSizeExceeded").subscribe((res: string) => {

                            errorMessage += ' ' + res;
                        });                       
                    } 

                    let responseMessage = result.SubmitApplicationResult.Info.Messages[0];

                    if(responseMessage.Details) {
                        responseMessage.Details += errorMessage;
                    }
                   
                    this.ErrorMessage = new ErrorMessage(responseMessage);
                }

                if (result.SubmitApplicationResult.Info.Status == "WARNING") {

                    this.driverOnbordingService.EmitGetApplication();
                }
            }
        });
    }

    public SaveAndNextSection(currentSectionHeading: string, currentFieldOrDocumentId: string) {

        if (this.IsFirstSection) {

            this.appComponent.ShowLoginView();
            return;
        }

        this.IsLastSection = false;
        this.IsSecondLastStep = false;

        if (currentFieldOrDocumentId) {

            var save = false;

            var value = "";
            var binaryValue = "";

            if(this.Section.Field) {                
                if(currentFieldOrDocumentId == "DriverPicture") {
                    //this.Section.Field.Value = this.ResizeDriverImage(this.Section.Field.Value, this.MaxImageWidth);
                    value = this.Section.Field.FileName;
                    binaryValue = this.Section.Field.Value;
                }
                else {
                    value = this.Section.Field.Value;
                }
            }
            else if(this.Section.Document) {
                //this.Section.Document.Value = this.ResizeDocImage(this.Section.Document.Value, this.MaxImageWidth);
                value = this.Section.Document.FileName;
                binaryValue = this.Section.Document.Value;
                this.TotalFileSize += (this.Section.Document && this.Section.Document.Size) ? this.Section.Document.Size : 0;
            }
            else {
                value = null;
                binaryValue = null;
            }

            var sectionIndex = this.Sections.findIndex(m => m.Heading == currentSectionHeading);

            if (sectionIndex > -1)
            {
                var section = this.Sections[sectionIndex];

                var fieldIndex = section.Fields.findIndex(m => m.Id == currentFieldOrDocumentId);

                if (fieldIndex > -1) {

                    if (section.Fields[fieldIndex].Value != this.Section.Field.Value || (currentFieldOrDocumentId == "DriverPicture" && section.Fields[fieldIndex].FileName != this.Section.Field.FileName)) {
                        save = true;
                    }

                    section.Fields[fieldIndex] = this.Section.Field;
                    this.Sections[sectionIndex] = section;
                }
                else {

                    var documentIndex = section.Documents.findIndex(m => m.Id == currentFieldOrDocumentId);

                    if (documentIndex > -1) {

                        if (section.Documents[documentIndex].FileName != this.Section.Document.FileName || section.Documents[documentIndex].Value != this.Section.Document.Value) {
                            save = true;
                        }

                        section.Documents[documentIndex] = this.Section.Document;
                        this.Sections[sectionIndex] = section;
                    }
                }
            }

            if (save) {

                this.spinner.show();

                this.driverOnbordingService.SaveValue(currentFieldOrDocumentId, value, binaryValue).subscribe((result: any) => {

                    this.spinner.hide();

                    if (result.SaveValueResult.Content) {

                        this.NextSection(currentSectionHeading, currentFieldOrDocumentId);
                    }
                    else if (result.SaveValueResult.Info.Status == "WARNING") {

                        this.driverOnbordingService.EmitGetApplication();
                    }
                });
            }
            else {

                this.NextSection(currentSectionHeading, currentFieldOrDocumentId);
            }
        }
        else {

            this.NextSection(currentSectionHeading, currentFieldOrDocumentId);
        }
    }

    public NextSection(currentSectionHeading: string, currentFieldOrDocumentId: string, skip?: boolean) {

        var section = new InternalSection();

        var sections = this.Sections;

        if (sections != null && sections.length > 0) {

            if (!currentSectionHeading) {
                var sec = sections[0];

                section.Heading = sec.Heading;
                section.Description = sec.Description;
                section.CanBeSkipped = sec.CanBeSkipped;
                section.SkipText = sec.SkipText;

                if (sec.Documents != null && sec.Documents.length) {
                    section.Document = sec.Documents[0];
                }
                else if (sec.Fields != null && sec.Fields.length) {
                    section.Field = sec.Fields[0];
                }
            }
            else {
                var currentSectionIndex = sections.findIndex(m => m.Heading == currentSectionHeading);

                this.CurrentIndex = currentSectionIndex;

                var currentSection = sections[currentSectionIndex];
                var nextSection = currentSectionIndex + 1 < sections.length ? sections[currentSectionIndex + 1] : null;


                if (currentSectionIndex == this.Sections.length - 2 &&
                    ((this.Section.Field && currentSection.Fields.length && this.Section.Field.Id == currentSection.Fields[currentSection.Fields.length - 1].Id) ||
                     (this.Section.Document && currentSection.Documents.length && this.Section.Document.Id == currentSection.Documents[currentSection.Documents.length - 1].Id && currentSection.Fields.length == 0))) {

                    this.SubmitApplication();
                    return;
                }
                else {
                    if (currentSectionIndex > -1) {
                        if (!currentFieldOrDocumentId) {
                            if (skip) {
                                section.Heading = nextSection.Heading;
                                section.Description = nextSection.Description;
                                section.CanBeSkipped = nextSection.CanBeSkipped;
                                section.SkipText = nextSection.SkipText;
                            }
                            else if (currentSection.Documents != null && currentSection.Documents.length) {
                                section.Heading = currentSection.Heading;
                                section.Description = currentSection.Description;
                                section.CanBeSkipped = currentSection.CanBeSkipped;
                                section.SkipText = currentSection.SkipText;

                                section.Document = currentSection.Documents[0];
                            }
                            else if (currentSection.Fields != null && currentSection.Fields.length) {
                                section.Heading = currentSection.Heading;
                                section.Description = currentSection.Description;
                                section.CanBeSkipped = currentSection.CanBeSkipped;
                                section.SkipText = currentSection.SkipText;

                                section.Field = currentSection.Fields[0];
                            }
                            else if (nextSection != null) {
                                this.CurrentIndex = currentSectionIndex + 1;

                                section.Heading = nextSection.Heading;
                                section.Description = nextSection.Description;
                                section.CanBeSkipped = nextSection.CanBeSkipped;
                                section.SkipText = nextSection.SkipText;
                            }
                        }
                        else {                                                
                            var currentFieldIndex = currentSection.Fields.findIndex(m => m.Id == currentFieldOrDocumentId);
                            var currentDocumentIndex = currentSection.Documents.findIndex(m => m.Id.toString() == currentFieldOrDocumentId);

                            if (currentDocumentIndex > -1) {
                                if (currentDocumentIndex + 1 < currentSection.Documents.length) {
                                    section.Heading = currentSection.Heading;
                                    section.Description = currentSection.Description;
                                    section.CanBeSkipped = currentSection.CanBeSkipped;
                                    section.SkipText = currentSection.SkipText;

                                    section.Document = currentSection.Documents[currentDocumentIndex + 1];

                                    if (currentSectionIndex == this.Sections.length - 2 && currentDocumentIndex == currentSection.Documents.length - 2 && currentSection.Fields.length == 0) {
                                        this.IsSecondLastStep = true;
                                    }
                                }
                                else if(currentSection.Fields.length > 0) {
                                    section.Heading = currentSection.Heading;
                                    section.Description = currentSection.Description;
                                    section.CanBeSkipped = currentSection.CanBeSkipped;
                                    section.SkipText = currentSection.SkipText;

                                    section.Field = currentSection.Fields[0];

                                    if (currentSectionIndex == this.Sections.length - 2 && currentDocumentIndex == currentSection.Documents.length - 1 && currentSection.Fields.length == 1) {
                                        this.IsSecondLastStep = true;
                                    }                                    
                                }
                                else if (nextSection != null) {
                                    section.Heading = nextSection.Heading;
                                    section.Description = nextSection.Description;
                                    section.CanBeSkipped = nextSection.CanBeSkipped;
                                    section.SkipText = nextSection.SkipText;
                                }
                            }
                            else if (currentFieldIndex > -1) {
                                if (currentFieldIndex + 1 < currentSection.Fields.length) {
                                    section.Heading = currentSection.Heading;
                                    section.Description = currentSection.Description;
                                    section.CanBeSkipped = currentSection.CanBeSkipped;
                                    section.SkipText = currentSection.SkipText;

                                    section.Field = currentSection.Fields[currentFieldIndex + 1];

                                    if (currentSectionIndex == this.Sections.length - 2 && currentFieldIndex == currentSection.Fields.length - 2) {
                                        this.IsSecondLastStep = true;
                                    }
                                }
                                else if (currentFieldIndex == currentSection.Fields.length) {
                                    section.Heading = currentSection.Heading;
                                    section.Description = currentSection.Description;
                                    section.CanBeSkipped = currentSection.CanBeSkipped;
                                    section.SkipText = currentSection.SkipText;

                                    if (currentSection.Documents != null && currentSection.Documents.length) {
                                        section.Document = currentSection.Documents[0];
                                    }
                                }
                                else if (nextSection != null) {
                                    section.Heading = nextSection.Heading;
                                    section.Description = nextSection.Description;
                                    section.CanBeSkipped = nextSection.CanBeSkipped;
                                    section.SkipText = nextSection.SkipText;

                                    this.CurrentIndex = this.CurrentIndex + 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        this.Section = new InternalSection(section);

        this.SetProgress();

        this.SetFieldFocus();

        return section;
    }

    public PreviousSection(currentSectionHeading: string, currentFieldOrDocumentId: string) {

        this.IsLastSection = false;
        this.IsSecondLastStep = false;
        var section = new InternalSection();
        var sections = this.Sections;

        if (sections != null && sections.length > 0 && currentSectionHeading) {

            var currentSectionIndex = sections.findIndex(m => m.Heading == currentSectionHeading);

            var currentSection = sections[currentSectionIndex];
            var previousSection = currentSectionIndex - 1 > -1 ? sections[currentSectionIndex - 1] : null;

            var currentFieldIndex = currentSection.Fields.findIndex(m => m.Id == currentFieldOrDocumentId);
            var currentDocumentIndex = currentSection.Documents.findIndex(m => m.Id.toString() == currentFieldOrDocumentId);

            var previousField = currentFieldIndex - 1 > -1 ? currentSection.Fields[currentFieldIndex - 1] : previousSection != null && previousSection.Fields.length > 0 ? previousSection.Fields[previousSection.Fields.length - 1] : null;
            var previousDocument = currentDocumentIndex - 1 > -1 ? currentSection.Documents[currentDocumentIndex - 1] : previousSection != null && previousSection.Documents.length > 0 ? previousSection.Documents[previousSection.Documents.length - 1] : null;

            var isCurrentSection = currentFieldIndex > -1 || currentFieldIndex - 1 > -1 || currentDocumentIndex > -1 || currentDocumentIndex - 1 > -1;

            section.Heading = isCurrentSection ? currentSection.Heading : previousSection.Heading;
            section.Description = isCurrentSection ? currentSection.Description : previousSection.Description;
            section.CanBeSkipped = isCurrentSection ? currentSection.CanBeSkipped : previousSection.CanBeSkipped;
            section.SkipText = isCurrentSection ? currentSection.SkipText : previousSection.SkipText;

            if (currentFieldIndex == 0 || currentDocumentIndex == 0) {

            }
            else if (currentFieldIndex > -1) {
                section.Field = previousField;
            }
            else if (currentDocumentIndex > -1) {
                section.Document = previousDocument;
            }
            else if (previousSection && previousSection.Fields.length) {
                section.Field = previousField;
            }
            else {
                section.Document = previousDocument;
            }

            this.CurrentIndex = isCurrentSection ? currentSectionIndex : currentSectionIndex - 1;

            this.Section = new InternalSection(section);

            this.SetProgress();
        }

        this.SetFieldFocus();

        if (this.CurrentIndex == 0) {

            this.appComponent.ShowLoginView();
        }

        return section;
    }

    public GetSectionNumber() {

        var currentNumber = 0;
        var totalSections = 0;
        var found = false;

        for (var i = 0; i < this.Sections.length; i++) {

            if (this.Sections[i].Fields.length > 0 || this.Sections[i].Documents.length > 0) {
                totalSections = totalSections + 1;

                if (!found) {
                    currentNumber = currentNumber + 1;
                    if (this.Sections[i].Heading == this.Section.Heading) {
                        found = true;
                    }
                }
            }
        }

        var sectionNumber = (found) ? currentNumber + "/" + totalSections : "";
        return sectionNumber;
    }

    public CancelApplication(startNewApplication: boolean) {

        if (startNewApplication) {

            this.CancelApp(startNewApplication);
        }
        else {

            this.translate.get("ConfirmCancel").subscribe((res: string) => {

                if (confirm(res)) {

                    this.CancelApp(startNewApplication);
                }
            });
        }
    }

    public SetFieldFocus() {

        setTimeout(() => {
            if (this.txtInput) {
                //this.txtInput.nativeElement.select();
            }
            else if (this.dateInput) {
                //this.dateInput.nativeElement.select();
                this.SetDateColor();
            }
        }, 100);
    }

    public focusIn() {

        this.IsFocused = true;
        this.SetKeyboardOpenForIOS();
    }

    public focusOut() {

        this.IsFocused = false;
        this.SetKeyboardCloseForIOS();
    }

    private SetDateColor() {

        var dateControl = this.dateInput.nativeElement;

        dateControl.onchange = function () {

            if (dateControl.value === '') {

                dateControl.classList.remove("filled-date");
                dateControl.classList.add("empty-date");
            } else {

                dateControl.classList.remove("empty-date");
                dateControl.classList.add("filled-date");
            }
        }

        if (dateControl.value === '') {

            dateControl.classList.remove("filled-date");
            dateControl.classList.add("empty-date");
        } else {

            dateControl.classList.remove("empty-date");
            dateControl.classList.add("filled-date");
        }
    }

    private CancelApp(startNewApplication: boolean) {

        this.spinner.show();

        this.driverOnbordingService.CancelApplication().subscribe((result: any) => {

            this.spinner.hide();

            if (result.CancelApplicationResult.Info.Status == "SUCCESS") {

                this.driverOnbordingService.EmitGetApplication();

                if (startNewApplication) {

                    this.GetApplicationStatus(true);
                }
                else {

                    this.translate.get("CancelledByAgent").subscribe((res: string) => {

                        this.Section.Status = res;
                    });

                    this.Section.Comment = "";
                    this.Section.CanCancel = false;
                    this.Section.CanStartNew = true;
                }
            }
            else if (result.CancelApplicationResult.Info.Messages.length > 0) {

                this.ErrorMessage = new ErrorMessage(result.CancelApplicationResult.Info.Messages[0]);
            }
        });
    }

    private applicationInProgress() {

        var inProgress = false;

        for (var i = 0; i < this.Sections.length; i++) {

            var fieldIndex = this.Sections[i].Fields.findIndex(m => m.Value != null && m.Value.length > 0);
            var documentIndex = this.Sections[i].Documents.findIndex(m => m.Value != null && m.Value.length > 0);

            if (fieldIndex > -1 || documentIndex > -1) {
                inProgress = true;
                break;
            }
        }

        return inProgress;
    }

    private GetApplicationStatus(isSecondSection?: boolean) {

        this.IsLastSection = false;
        this.IsSecondLastStep = false;
        this.spinner.show();

        this.driverOnbordingService.GetApplicationStatus().subscribe((result: any) => {

            this.spinner.hide();

            if (result.GetApplicationStatusResult.Info.Status == "WARNING") {

                if (isSecondSection) {

                    this.SelectSecondSection();
                }
                else {

                    this.SelectFirstMissingFieldOrDocument();
                }
            }
            else if (result.GetApplicationStatusResult.Info.Status == "SUCCESS") {

                this.translate.get(result.GetApplicationStatusResult.Content.Status).subscribe((res: string) => {
                    this.SelectLastSection(result.GetApplicationStatusResult.Content.Status, res, result.GetApplicationStatusResult.Content.Comment);
                });
            }
            else if (result.GetApplicationStatusResult.Info.Messages.length > 0) {

                this.ErrorMessage = new ErrorMessage(result.GetApplicationStatusResult.Info.Messages[0]);
            }
        });
    }

    private SelectLastSection(status: string, statusText: string, comment?: string) {

        var section = new InternalSection();

        if (this.Sections.length > 0) {

            this.IsLastSection = true;

            this.CurrentIndex = this.Sections.length;

            section.Heading = this.Sections[this.Sections.length - 1].Heading;
            section.Description = this.Sections[this.Sections.length - 1].Description;
            section.CanBeSkipped = this.Sections[this.Sections.length - 1].CanBeSkipped;
            section.SkipText = this.Sections[this.Sections.length - 1].SkipText;

            section.Status = statusText;
            section.Comment = comment;

            if (status == 'Submitted' || status == 'InProgress') {
                section.CanCancel = true;
                section.CanStartNew = false;
            }
            else if (status == 'Declined' || status == 'CancelledByAgent' || status == 'CancelledByVendor') {
                section.CanCancel = false;
                section.CanStartNew = true;
            }
        }

        this.Section = new InternalSection(section);

        this.SetProgress();
    }

    private SelectFirstSection() {

        this.NextSection(null, null);
    }

    private SelectSecondSection() {

        if (this.Sections.length > 0) {

            var firstSection = this.Sections[0];
            this.NextSection(firstSection.Heading, null);
        }
    }

    private SelectFirstMissingFieldOrDocument() {

        this.IsSecondLastStep = false;
        var section = new InternalSection();

        for (var i = 0; i < this.Sections.length; i++) {

            section.Heading = this.Sections[i].Heading;
            section.Description = this.Sections[i].Description;
            section.CanBeSkipped = this.Sections[i].CanBeSkipped;
            section.SkipText = this.Sections[i].SkipText;

            if (this.ProgressPercentage < 0) {
                break;
            }

            var fieldIndex = this.Sections[i].Fields.findIndex(m => (m.Value == null || m.Value.length <= 0) && m.Mandatory);
            var documentIndex = this.Sections[i].Documents.findIndex(m => (m.Value == null || m.Value.length <= 0) && m.Mandatory);

            if (i == this.Sections.length - 2 &&
                ((fieldIndex > -1 && fieldIndex == this.Sections[i].Fields.length - 1) || (documentIndex > -1 && documentIndex == this.Sections[i].Documents.length - 1))) {
                this.IsSecondLastStep = true;
            }

            if (documentIndex > -1) {

                if (this.Sections[i].Fields.length == 0 && documentIndex > 0) {
                    section.Document = this.Sections[i].Documents[documentIndex];
                }

                this.CurrentIndex = i;
                break;
            }
            else if (fieldIndex > -1) {

                if (fieldIndex > 0) {
                    section.Field = this.Sections[i].Fields[fieldIndex];
                }

                this.CurrentIndex = i;
                break;
            }
        }

        // if beginning of the first section containing fields then take to the very start
        if (this.CurrentIndex == 1 && !section.Field && !section.Document && this.Sections.length > 0) {

            this.CurrentIndex = 0;
            section.Heading = this.Sections[0].Heading;
            section.Description = this.Sections[0].Description;
            section.CanBeSkipped = this.Sections[0].CanBeSkipped;
            section.SkipText = this.Sections[0].SkipText;
        }

        this.Section = new InternalSection(section);

        this.SetProgress();
    }

    private SetProgress() {

        var currentItemPosition = 0;
        var totalItemsCount = 0;
        var found = false;

        for (var sectionIndex = 0; sectionIndex < this.Sections.length; sectionIndex++) {

            totalItemsCount = totalItemsCount + this.Sections[sectionIndex].Fields.length;
            totalItemsCount = totalItemsCount + this.Sections[sectionIndex].Documents.length;

            if (!found) {

                for (var fieldIndex = 0; fieldIndex < this.Sections[sectionIndex].Fields.length; fieldIndex++) {

                    currentItemPosition = currentItemPosition + 1;
                    if (this.Section.Field && this.Sections[sectionIndex].Fields[fieldIndex].Id == this.Section.Field.Id) {
                        found = true;
                        break;
                    }
                }

                for (var documentIndex = 0; documentIndex < this.Sections[sectionIndex].Documents.length; documentIndex++) {

                    currentItemPosition = currentItemPosition + 1;
                    if (this.Section.Document && this.Sections[sectionIndex].Documents[documentIndex].Id == this.Section.Document.Id) {
                        found = true;
                        break;
                    }
                }
            }
        }

        this.ProgressPercentage = (currentItemPosition - 1) * 100 / totalItemsCount;
    }

    private SetupKeyboardOpenEvent() {

        var innerHeight = window.innerHeight;

        window.onresize = (e) => {

            this.ngZone.run(() => {

                var selected = (this.txtInput || this.dateInput) && this.IsFocused;
                var keyboardOpen = window.innerHeight < innerHeight;
                //var hasScrollbar = window.innerHeight < screen.height;

                if (selected && keyboardOpen) {
                    this.IsKeyboardOpen = true;
                }
                else {
                    this.IsKeyboardOpen = false;
                }
            });
        }
    }

    private SetKeyboardOpenForIOS() {

        if (this.IsiOS) {

            setTimeout(() => {

                if (this.proceedBtn) {
                    this.proceedBtn.nativeElement.classList.remove("proceed-btn");
                    this.proceedBtn.nativeElement.classList.add("proceed-btn2");
                }

                this.IsKeyboardOpen = true;
                window.scrollTo(0, 20);
            }, 200);
        }
    }

    private SetKeyboardCloseForIOS() {

        if (this.IsiOS) {

            setTimeout(() => {

                if (this.proceedBtn) {
                    this.proceedBtn.nativeElement.classList.remove("proceed-btn2");
                    this.proceedBtn.nativeElement.classList.add("proceed-btn");
                }

                this.IsKeyboardOpen = false;
                window.scrollTo(0, 0);
            }, 200);
        }
    }

    private ResizeDriverImage(base64, newWidth) {

        var oldWidth = this.driverImage.nativeElement.width;
        var oldHeight = this.driverImage.nativeElement.height;

        if (oldWidth > newWidth) {

            this.driverImage.nativeElement.width = newWidth;
            this.driverImage.nativeElement.height = newWidth * oldHeight / oldWidth;

            var canvas = document.createElement('canvas');

            canvas.width = this.driverImage.nativeElement.width;
            canvas.height = this.driverImage.nativeElement.height;

            canvas.getContext('2d').drawImage(this.docImage.nativeElement, 0, 0, canvas.width, canvas.height);

            var src = canvas.toDataURL();

            base64 = src.split("base64,")[1];
        }

        return base64;
    }

    private ResizeDocImage(base64, newWidth) {

        var oldWidth = this.docImage.nativeElement.width;
        var oldHeight = this.docImage.nativeElement.height;

        if (oldWidth > newWidth) {

            this.docImage.nativeElement.width = newWidth;
            this.docImage.nativeElement.height = newWidth * oldHeight / oldWidth;

            var canvas = document.createElement('canvas');

            canvas.width = this.docImage.nativeElement.width;
            canvas.height = this.docImage.nativeElement.height;

            canvas.getContext('2d').drawImage(this.docImage.nativeElement, 0, 0, canvas.width, canvas.height);

            var src = canvas.toDataURL();

            base64 = src.split("base64,")[1];
        }

        return base64;
    }    
}
