export class Culture {

    public cultureId: number;
    public cultureCode: string;
    public geoDatabase: string;
    public description: string;
    public countryCallingCodes: string;
    public country: string;

    constructor(culture?) {

        if (culture) {
            this.cultureId = culture.CultureId;
            this.cultureCode = culture.CultureCode;
            this.geoDatabase = culture.GeoDatabase;
            this.description = culture.Description;
            this.countryCallingCodes = culture.CountryCallingCodes;

            if (culture.Description != null && culture.Description.indexOf('-') > -1) {

                this.country = culture.Description.substr(culture.Description.indexOf('-') + 1).trim();
            }
        }
    }
}