import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { SectionComponent } from './section/section.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';

import { RepositoryService } from '../Services/Repository.service';
import { DriverOnboardingService } from '../Services/DriverOnboarding.service';
import { HelpersService } from '../Services/helpers.service';
import { SessionService } from '../Services/Session.service';
import { LoginService } from '../Services/login.service';
import { ResetPasswordService } from '../Services/resetPassword.service';
import { SettingsService } from '../Services/Settings.service';

import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomNgbDateParserFormatter } from './CustomNgbDateParserFormatter';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { faChevronLeft, faChevronRight, faChevronCircleRight, faPlusCircle, faEnvelope, faKey, faSignInAlt, faAngleLeft, faFileAlt, faUser, faUserAlt, faPhone, faGlobe, faUserCheck } from '@fortawesome/pro-light-svg-icons';
library.add(faChevronLeft, faChevronRight, faChevronCircleRight, faPlusCircle, faEnvelope, faKey, faSignInAlt, faAngleLeft, faFileAlt, faUser, faUserAlt, faPhone, faGlobe, faUserCheck );

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        SectionComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        DeviceDetectorModule.forRoot(),
        HttpClientModule,
        NgbModule,
        FontAwesomeModule,
        NgxSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        }),
        NgCircleProgressModule.forRoot({
            // responsive: true,
            backgroundPadding: 7,
            radius: 60,
            space: -2,
            maxPercent: 100,
            outerStrokeWidth: 2,
            outerStrokeColor: "#e7e8ea",
            innerStrokeColor: "#808080",
            innerStrokeWidth: 2,
            unitsColor: "#e7e8ea",
            titleColor: "#e7e8ea",
            animateTitle: false,
            animationDuration: 1000,
            titleFontSize: "42",
            showTitle: true,
            showUnits: true,
            unitsFontSize: "30",
            subtitleFontSize: "20",
            showSubtitle: false,
            clockwise: true
        }),
        RouterModule.forRoot([
            {
                path: ":agentId",
                component: AppComponent
            }
        ]),
    ],
    providers: [
        RepositoryService,
        DriverOnboardingService,
        HelpersService,
        LoginService,
        ResetPasswordService,
        SessionService,
        SettingsService,
        { provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter() }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
