export class Settings {

    public agentId: number;
    public agentName: string;
    public vendorId: number;
    public culture: string;

    constructor(settings?) {

        if (settings) {
            this.agentId = settings.AgentId;
            this.agentName = settings.AgentName;
            this.vendorId = settings.VendorId;
            this.culture = settings.Culture;
        }
    }
}