import { SectionField } from "./Field.model";
import { SectionDocument } from "./Document.model";
import { element } from "@angular/core/src/render3/instructions";

export class Section {
    public Heading: string;
    public Description: string;
    public CanBeSkipped: boolean;
    public SkipText: string;
    public Fields: SectionField[];
    public Documents: SectionDocument[];

    public constructor(section) {
        this.Heading = section.Heading;
        this.Description = section.Description;
        this.CanBeSkipped = section.CanBeSkipped;
        this.SkipText = section.SkipText;
        this.Fields = section.Fields.map(element => {
            return new SectionField(element);
        });
        this.Documents = section.Documents.map(element => {
            return new SectionDocument(element);
        });
    }
}