import { SectionField } from "./Field.model";
import { SectionDocument } from "./Document.model";

export class InternalSection {

    public Heading: string;
    public Description: string;
    public CanBeSkipped: boolean;
    public SkipText: string;
    public Field: SectionField;
    public Document: SectionDocument;

    public Status: string;
    public Comment: string; 
    public CanCancel: boolean; 
    public CanStartNew: boolean; 

    constructor(section?) {
        if (section) {
            this.Heading = section.Heading;
            this.Description = section.Description;
            this.CanBeSkipped = section.CanBeSkipped;
            this.SkipText = section.SkipText;
            this.Field = section.Field ? new SectionField(section.Field) : null;
            this.Document = section.Document ? new SectionDocument(section.Document) : null;
            
            this.Status = section.Status;
            this.Comment = section.Comment;
            this.CanCancel = section.CanCancel;
            this.CanStartNew = section.CanStartNew;
        }
    }
}