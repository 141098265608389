import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from 'src/Models/Error.model';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    constructor(private ngbDateParserFormatter: NgbDateParserFormatter, private translate: TranslateService) { }

    public FormatDateTextFromView(date, section) {

        if (date != null) {

            var formattedDate = this.ngbDateParserFormatter.format(date);
            section.Field.Value = this.FormatDateValue(date);
            return formattedDate;
        }
    }

    public FormatDateTextFromModel(date) {

        if (date != null) {

            var dt = new Date(date);
            var dt2 = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };
            var formattedDate = this.ngbDateParserFormatter.format(dt2);
            return formattedDate;
        }
    }

    public OnlyNumeric(e) {

        e = (e) ? e : window.event
        var charCode = (e.which) ? e.which : e.keyCode

        if (charCode > 43 && charCode < 58) {

            return true;
        }

        return false;
    }

    public GetYearsList(numberOfYears) {

        var years = [];
        var currentYear = new Date().getFullYear();
        for (let i = currentYear - numberOfYears; i <= currentYear; i++) {
            years.push(i);
        }
        return years;
    }

    public Sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }    

    public handleInputChange(e, section) {

        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var fileSizeInMB = file.size / (1024 * 1024);

        if (fileSizeInMB > 5) {
            this.ShowFileSizeExceedsMessage();
            return;
        }

        var reader = new FileReader();
        var pattern = /image-*/;
        if (section.Field && !file.type.match(pattern)) {
            this.ShowInvalidFileFormatMessage();
            return;
        }

        if (section.Document && (file.type.includes('x-msdownload') || file.type.includes('x-ms-installer'))) {
            this.ShowInvalidFileFormatMessage();
            return;
        }

        this.section = section;

        if (section.Document) {
            section.Document.FileName = file.name;
            section.Document.Size = file.size;
        }
        else {
            section.Field.FileName = file.name;
        }

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    private ShowFileSizeExceedsMessage() {

        this.translate.get('FileSizeExceeds5MB').subscribe((res: string) => {
            alert(res);
        });
    }

    private ShowInvalidFileFormatMessage() {

        this.translate.get('InvalidFileFormat').subscribe((res: string) => {
            alert(res);
        });
    }

    private FormatDateValue(date) {

        return date.year + "-" + this.PadNumber(date.month) + "-" + this.PadNumber(date.day);
    }

    private section = null;

    private _handleReaderLoaded(e) {

        var binaryString = e.target.result;

        //var base64String = btoa(binaryString);
        var base64String = binaryString.split("base64,")[1];

        if (this.section.Document) {
            this.section.Document.Value = base64String;
        }
        else if (this.section.Field) {
            this.section.Field.Value = base64String;
        }
    }

    private strToBuffer(string) {
        let arrayBuffer = new ArrayBuffer(string.length * 1);
        let newUint = new Uint8Array(arrayBuffer);
        newUint.forEach((_, i) => {
            newUint[i] = string.charCodeAt(i);
        });
        return newUint;
    }

    private PadNumber(value: number) {
        if (Number.isInteger(value)) {
            return `0${value}`.slice(-2);
        } else {
            return "";
        };
    };
}
