import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { SessionService } from '../../Services/Session.service';
import { LoginModel } from '../../Models/Login.model';
import { DriverOnboardingService } from '../../Services/DriverOnboarding.service';
import { ErrorMessage } from '../../Models/Error.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @ViewChild("contactemail") contactemail: ElementRef;
    @ViewChild("contactpassword") contactpassword: ElementRef;
    @ViewChild("loginBtn") loginBtn: ElementRef;

    public model = new LoginModel();
    public loggingIn = false;
    public IsiOS: boolean = false;
    public errorMessage = null;
    public BackgroundColours = ["#ff0000" /* error */, "#EFA136", "#F47941", "#B22E40", "#05858C", "#323742" /* message */, "#50BC89", "#33B6AE", "#2A5AA6" /* login */, "#FFD13A"];

    constructor(
        private sessionService: SessionService,
        private loginService: LoginService,
        private driverOnbordingService: DriverOnboardingService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private appComponent: AppComponent,
        private deviceService: DeviceDetectorService) {

        this.IsiOS = this.deviceService.os == "iOS";
    }

    ngOnInit() {
    }

    public login() {

        this.errorMessage = null;

        if (this.sessionService.agentId <= 0) {

            this.errorMessage = new ErrorMessage();
            this.errorMessage.IsMessage = true;

            this.translate.get('NotEnabled').subscribe((res: string) => {

                this.errorMessage.Summary = res;

                this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                    this.errorMessage.Details = res;
                });
            });
        }
        else {

            this.loggingIn = true;
            this.spinner.show();

            this.driverOnbordingService.IsDriverOnboardingEnabled().subscribe((result: any) => {

                if (result.IsDriverOnboardingEnabledResult.Info.Status == "SUCCESS" && result.IsDriverOnboardingEnabledResult.Content) {

                    this.Authenticate();
                }
                else {

                    this.spinner.hide();
                    this.loggingIn = false;

                    this.errorMessage = new ErrorMessage();
                    this.errorMessage.IsMessage = true;

                    this.translate.get('NotEnabled').subscribe((res: string) => {

                        this.errorMessage.Summary = res;

                        this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                            this.errorMessage.Details = res;
                        });
                    });
                }
            });
        }
    }

    public register() {

        this.appComponent.ShowRegisterView();
    }

    public resetPassword() {

        this.appComponent.ShowResetPasswordView();
    }

    public loginOnEnterKeyPress() {

        if (!this.validateEmail()) {

            this.contactemail.nativeElement.select();
        }
        else if (!this.model.password) {

            this.contactpassword.nativeElement.select();
        }
        else {

            this.login();
        }
    }

    public focusIn() {

        this.SetKeyboardOpenForIOS();
    }

    public focusOut() {

        this.SetKeyboardCloseForIOS();
    }

    private Authenticate() {
        this.loginService.Authenticate(this.model.username, this.model.password).subscribe((result: any) => {

            this.spinner.hide();
            this.loggingIn = false;

            if (result.AuthenticateResult.Info.Status == "SUCCESS" && result.AuthenticateResult.Content) {

                this.sessionService.SetSessionToken(result.AuthenticateResult.Content);
                this.driverOnbordingService.EmitGetApplication();
            }
            else {
                this.errorMessage = new ErrorMessage();

                this.translate.get('LoginFailed').subscribe((res: string) => {
                    this.errorMessage.Details = res;
                });
            }
        });
    }

    private validateEmail() {

        var valid = false;

        if (this.model.username) {

            var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            valid = pattern.test(this.model.username);
        }

        return valid;
    }

    private SetKeyboardOpenForIOS() {

        if (this.IsiOS) {

            setTimeout(() => {

                if (this.loginBtn) {
                    this.loginBtn.nativeElement.classList.remove("login-btn");
                    this.loginBtn.nativeElement.classList.add("login-btn3");
                }
            }, 200);
        }
    }

    private SetKeyboardCloseForIOS() {

        if (this.IsiOS) {

            setTimeout(() => {

                if (this.loginBtn) {
                    this.loginBtn.nativeElement.classList.remove("login-btn3");
                    this.loginBtn.nativeElement.classList.add("login-btn");
                }
            }, 200);
        }
    }
}
