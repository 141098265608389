import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { RegisterService } from '../../Services/register.service';
import { SessionService } from '../../Services/Session.service';
import { Register } from '../../Models/Register.model';
import { DriverOnboardingService } from '../../Services/DriverOnboarding.service';
import { ErrorMessage } from '../../Models/Error.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../app.component';
import { CountryCode } from 'src/Models/CountryCode.model';
import { Culture } from 'src/Models/Culture.model';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    @ViewChild("contactFirstName") contactFirstName: ElementRef;
    @ViewChild("contactLastName") contactLastName: ElementRef;
    @ViewChild("contactCountryCode") contactCountryCode: ElementRef;
    @ViewChild("contactTelephone") contactTelephone: ElementRef;
    @ViewChild("contactEmail") contactEmail: ElementRef;
    @ViewChild("contactPassword") contactPassword: ElementRef;
    @ViewChild("contactConfirmPassword") contactConfirmPassword: ElementRef;
    @ViewChild("confirmValidationCode") confirmValidationCode: ElementRef;

    public model = new Register();
    public countryCodes = [];
    public registering = false;
    public validate = false;
    public validationType = "none";
    public validationCode = "";
    public errorMessage = null;
    public BackgroundColours = ["#ff0000" /* error */, "#EFA136", "#F47941", "#B22E40", "#05858C", "#323742" /* message */, "#50BC89", "#33B6AE", "#2A5AA6" /* login */, "#FFD13A"];

    constructor(
        private sessionService: SessionService,
        private registerService: RegisterService,
        private driverOnbordingService: DriverOnboardingService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private ngZone: NgZone,
        private appComponent: AppComponent,
        private deviceService: DeviceDetectorService) {

    }

    ngOnInit() {

        this.driverOnbordingService.GetCountryCodes().subscribe((result: any) => {

            if (result.GetCountryCodesResult.Info.Status == "SUCCESS" && result.GetCountryCodesResult.Content) {

                this.countryCodes = result.GetCountryCodesResult.Content;

                this.driverOnbordingService.GetAgentCulture().subscribe((result: any) => {

                    if (result.GetAgentCultureResult.Info.Status == "SUCCESS" && result.GetAgentCultureResult.Content) {

                        var culture = new Culture(result.GetAgentCultureResult.Content);

                        for (var i = 0; i < this.countryCodes.length; i++) {

                            var countryCode = new CountryCode(this.countryCodes[i]);

                            if (countryCode.country == culture.country) {
                                this.model.countryCodeId = countryCode.countryCodeID;
                                break;
                            }
                        }
                    }
                    else {
                        this.model.countryCodeId = 221;
                    }
                });
            }
            else {

                this.errorMessage = new ErrorMessage();
                this.errorMessage.IsMessage = true;
                this.errorMessage.Summary = result.CountryCodesResult.Info.Messages[0].Summary;
                this.errorMessage.Details = result.CountryCodesResult.Info.Messages[0].Details;
            }
        });
    }

    public registerWithValidate() {

        this.errorMessage = null;

        if (this.sessionService.agentId <= 0) {

            this.errorMessage = new ErrorMessage();
            this.errorMessage.IsMessage = true;

            this.translate.get('NotEnabled').subscribe((res: string) => {

                this.errorMessage.Summary = res;

                this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                    this.errorMessage.Details = res;
                });
            });
        }
        else {

            this.spinner.show();

            this.driverOnbordingService.IsDriverOnboardingEnabled().subscribe((result: any) => {

                if (result.IsDriverOnboardingEnabledResult.Info.Status == "SUCCESS" && result.IsDriverOnboardingEnabledResult.Content) {

                    this.doRegisterWithValidate();
                }
                else {

                    this.spinner.hide();

                    this.errorMessage = new ErrorMessage();
                    this.errorMessage.IsMessage = true;

                    this.translate.get('NotEnabled').subscribe((res: string) => {

                        this.errorMessage.Summary = res;

                        this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                            this.errorMessage.Details = res;
                        });
                    });
                }
            });
        }
    }

    public numberOnly(event): boolean {

        const charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {

            return false;
        }

        return true;
    }

    public setInternationalNumber() {
        if (this.model.telephone) {

            var dialingCode = "44";

            var internationalNumber = this.model.telephone.toString().replace("/^0-9/g", "");

            if (internationalNumber.indexOf('0') == 0) {

                internationalNumber.substr(1);
            }

            for (var i = 0; i < this.countryCodes.length; i++) {

                var countryCode = new CountryCode(this.countryCodes[i]);

                if (countryCode.countryCodeID == this.model.countryCodeId) {

                    dialingCode = countryCode.dialingCode;
                    break;
                }
            }

            return "+" + dialingCode + internationalNumber;
        }

        return this.model.telephone;
    }

    public validateBeforeRegister() {

        if (!this.model.firstName) {

            this.contactFirstName.nativeElement.select();
        }
        else if (!this.model.lastName) {

            this.contactLastName.nativeElement.select();
        }
        else if (!this.validateEmail()) {

            this.contactEmail.nativeElement.select();
        }
        else if (!this.model.password) {

            this.contactPassword.nativeElement.select();
        }
        else if (!this.model.confirmPassword || this.model.confirmPassword != this.model.password) {

            this.contactConfirmPassword.nativeElement.select();
        }
        else {

            this.registerWithValidate();
        }
    }

    public focusIn() {

    }

    public focusOut() {

    }

    public CancelValidation() {

        this.validate = false;
        this.validationCode = "";
    }

    public registerAfterValidate() {

        if (this.model.confirmValidationCode == this.validationCode) {

            this.validate = true;
            this.doRegister();
        }
        else {

            this.errorMessage = new ErrorMessage();
            this.translate.get('ValidationCodeMismatchError').subscribe((res: string) => {
                this.errorMessage.Details = res;
            });

            this.confirmValidationCode.nativeElement.select();
        }
    }

    private doRegister() {

        this.registering = true;
        this.spinner.show();

        this.registerService.Register(this.model.firstName, this.model.lastName, this.model.countryCodeId, this.setInternationalNumber(), this.model.email, this.model.password).subscribe((result: any) => {

            this.spinner.hide();
            this.registering = false;

            if (result.RegisterResult.Info.Status == "SUCCESS" && result.RegisterResult.Content) {

                this.sessionService.SetSessionToken(result.RegisterResult.Content);
                this.driverOnbordingService.EmitGetApplication();
            }
            else {
                this.errorMessage = new ErrorMessage();

                if (result.RegisterResult.Info.Messages.length) {

                    this.errorMessage.Details = result.RegisterResult.Info.Messages[0].Summary;
                }
                else {

                    this.translate.get('RegisterFailed').subscribe((res: string) => {
                        this.errorMessage.Details = res;
                    });
                }
            }
        });
    }

    private doRegisterWithValidate() {

        this.registering = true;
        this.spinner.show();
        this.validationCode = Math.floor((Math.random() * 9999) + 1001).toString();

        this.registerService.RegisterWithValidate(this.model.firstName, this.model.lastName, this.model.countryCodeId, this.setInternationalNumber(), this.model.email, this.model.password, this.validationCode).subscribe((result: any) => {

            this.spinner.hide();
            this.registering = false;

            if (result.RegisterWithValidateResult.Info.Status == "SUCCESS" && result.RegisterWithValidateResult.Content) {

                this.validationType = result.RegisterWithValidateResult.Content.ValidationType;

                if (result.RegisterWithValidateResult.Content.ValidationType == "none") {

                    this.sessionService.SetSessionToken(result.RegisterWithValidateResult.Content.Token);
                    this.driverOnbordingService.EmitGetApplication();
                }
                else {

                    this.validate = true;
                }
            }
            else {
                this.errorMessage = new ErrorMessage();

                if (result.RegisterWithValidateResult.Info.Messages.length) {

                    this.errorMessage.Details = result.RegisterWithValidateResult.Info.Messages[0].Summary;
                }
                else {

                    this.translate.get('RegisterFailed').subscribe((res: string) => {
                        this.errorMessage.Details = res;
                    });
                }
            }
        });
    }

    private validateEmail() {

        var valid = false;

        if (this.model.email) {

            var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            valid = pattern.test(this.model.email);
        }

        return valid;
    }

    public login() {

        this.appComponent.ShowLoginView();
    }
}