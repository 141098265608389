import { Injectable } from "@angular/core";
import { RepositoryService } from "./Repository.service";
import { Observable, Subject } from "rxjs";
import { EndpointService } from "./Endpoint.service";
import { SessionService } from "./Session.service";

@Injectable()
export class DriverOnboardingService {
    private GetApplicationSubject = new Subject<any>();

    constructor(private repository: RepositoryService, private session: SessionService) {
    }

    public EmitGetApplication() {
        this.GetApplicationSubject.next();
    };

    public OnGetApplication(): Observable<any> {
        return this.GetApplicationSubject.asObservable();
    };

    public IsDriverOnboardingEnabled() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.IsDriverOnboardingEnabled, payload);
    }

    public GetSettings() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.GetSettings, payload);
    }

    public GetCountryCodes() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.GetCountryCodes, payload);
    }

    public GetAgentCulture() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.GetAgentCulture, payload);
    }    

    public GetApplication(ignoreUser: boolean) {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            ignoreUser: ignoreUser
        };

        return this.repository.post(EndpointService.GetApplication, payload);
    }

    public GetApplicationStatus() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.GetApplicationStatus, payload);
    }

    public CancelApplication() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.CancelApplication, payload);
    }    

    public SubmitApplication() {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId
        };

        return this.repository.post(EndpointService.SubmitApplication, payload);
    }

    public SaveValue(fieldId: string, fieldValue?: string, binaryValue?: string) {
        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            fieldId: fieldId,
            fieldValue: fieldValue,
            binaryValue: binaryValue
        };
        return this.repository.post(EndpointService.SaveValue, payload);
    }
}