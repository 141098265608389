export class CountryCode {

    public countryCodeID: number;
    public cCode: string;
    public country: string;
    public dialingCode: string;

    constructor(countryCode?) {

        if (countryCode) {
            this.countryCodeID = countryCode.CountryCodeID;
            this.cCode = countryCode.CCode;

            if (countryCode.CCode != null && countryCode.CCode.indexOf('(') > -1) {

                this.country = countryCode.CCode.substr(0, countryCode.CCode.indexOf('(') - 1).trim();
                this.dialingCode = countryCode.CCode.substr(countryCode.CCode.indexOf('(') + 1, countryCode.CCode.indexOf(')') - countryCode.CCode.indexOf('(') - 1).replace('+', '').trim();
            }
        }
    }
}