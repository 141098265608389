import { Injectable } from '@angular/core';
import { RepositoryService } from './Repository.service';
import { EndpointService } from './Endpoint.service';
import { SessionService } from './Session.service';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    constructor(private repository: RepositoryService, private session: SessionService) { }

    public Register(firstName: string, lastName: string, countryCodeId: number, telephone: string, email: string, password: string) {

        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            firstName: firstName,
            lastName: lastName,
            countryCodeId: countryCodeId,
            telephone: telephone,
            email: email,
            password: Md5.hashStr(password)
        };

        return this.repository.post(EndpointService.Register, payload);
    }

    public RegisterWithValidate(firstName: string, lastName: string, countryCodeId: number, telephone: string, email: string, password: string, validationCode: string) {

        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            firstName: firstName,
            lastName: lastName,
            countryCodeId: countryCodeId,
            telephone: telephone,
            email: email,
            password: Md5.hashStr(password),
            validationCode: validationCode
        };

        return this.repository.post(EndpointService.RegisterWithValidate, payload);
    }    
}
