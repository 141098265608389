import { SessionData } from "../Models/SessionData.model";
import { Injectable } from "@angular/core";

@Injectable()
export class SessionService {

    private clientApplication = "DriverOnboarding";
    public agentId = 0;
    public sessionData = new SessionData(this.clientApplication, 0);

    constructor() {
    }

    public SetSessionAgentId(agentId: number) {

        this.setItem("agentId", agentId);
        this.sessionData.agentId = agentId;
    }

    public SetSessionToken(token: string) {

        this.setItem("token", token);
        this.sessionData.token = token;
    }

    public GetSessionToken() {

        var agentId = this.getItem("agentId");

        if (agentId && agentId != this.agentId) {

            this.sessionData.token = "";
            this.deleteItem("token");
        }
        else {

            var token = this.getItem("token");
            this.sessionData.token = token;
        }
    }

    private deleteItem(key): void {
        delete localStorage[key];
    };

    private getItem(key): any {
        return JSON.parse(localStorage.getItem(key));
    };

    private setItem(key, payload): void {
        localStorage.setItem(key, this.stringify(payload));
    };

    private stringify(json): string {
        return JSON.stringify(json);
    };
}