import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './Session.service';

@Injectable()
export class RepositoryService {
    constructor(private http: HttpClient, private session: SessionService) { }

    delete(url: string, options?: any) {

        if (this.session.sessionData.token) {

            if (options && options.headers) {
                Object.assign(options.headers, { 'Authentication-Token': this.session.sessionData.token });
            } else {
                options = {
                    headers: new HttpHeaders({
                        'Authentication-Token': this.session.sessionData.token
                    })
                };
            };
        }

        return this.http.delete(url, options);
    }

    get(url: string, options?: any) {

        if (this.session.sessionData.token) {

            if (options && options.headers) {
                Object.assign(options.headers, { 'Authentication-Token': this.session.sessionData.token });
            } else {
                options = {
                    headers: new HttpHeaders({
                        'Authentication-Token': this.session.sessionData.token
                    })
                };
            };
        }

        return this.http.get(url, options);
    }

    post(url: string, payload?: any, options?: any) {

        if (this.session.sessionData.token) {

            if (options && options.headers) {
                Object.assign(options.headers, { 'Authentication-Token': this.session.sessionData.token });
            } else {
                options = {
                    headers: new HttpHeaders({
                        'Authentication-Token': this.session.sessionData.token
                    })
                };
            };
        }

        return this.http.post(url, payload, options);
    }

    put(url: string, payload?: any, options?: any) {

        if (this.session.sessionData.token) {

            if (options && options.headers) {
                Object.assign(options.headers, { 'Authentication-Token': this.session.sessionData.token });
            } else {
                options = {
                    headers: new HttpHeaders({
                        'Authentication-Token': this.session.sessionData.token
                    })
                };
            };
        }

        return this.http.put(url, payload, options);
    }
}