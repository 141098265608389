export class Register {

    public firstName: string;
    public lastName: string;
    public countryCodeId: number;
    public telephone: string;
    public email: string;
    public password: string;
    public confirmPassword: string;
    public confirmValidationCode: string;

    constructor(register?) {

        if (register) {

            this.firstName = register.firstName;
            this.lastName = register.lastName;
            this.countryCodeId = register.countryCodeId;
            this.telephone = register.telephone;
            this.email = register.email;
            this.password = register.password;
            this.confirmPassword = register.confirmPassword;
            this.confirmValidationCode = register.confirmValidationCode;
        }
    }
}