import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ErrorMessage } from '../../Models/Error.model';
import { DriverOnboardingService } from '../../Services/DriverOnboarding.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
    @Input('ErrorMessage') ErrorMessage = new ErrorMessage();
    @Output('ErrorMessageChange') ErrorMessageChange = new EventEmitter();

    constructor(private driverOnbordingService: DriverOnboardingService) {

    }

    ngOnInit() {

    }

    public TryAgain() {

        this.ErrorMessageChange.emit(null);

        if (this.ErrorMessage.StartAgain) {
            this.driverOnbordingService.EmitGetApplication();
        }
    }
}
