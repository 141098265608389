export class SectionDocument {
    public Id: string;
    public Name: string;
    public Description: string;
    public FileName: string;
    public Value: string;
    public Mandatory: boolean;
    public Category: string;
    public Size: number;

    public constructor(sectionDocument?) {

        if (sectionDocument) {
            this.Id = sectionDocument.Id;
            this.Name = sectionDocument.Name;
            this.Description = sectionDocument.Description;
            this.FileName = sectionDocument.FileName;
            this.Value = sectionDocument.Value;
            this.Mandatory = sectionDocument.Mandatory;
            this.Category = sectionDocument.Category;
            this.Size = sectionDocument.Size;
        }
    }
}