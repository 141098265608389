export class SessionData {

    public clientApplication: string;
    public agentId: number;
    public token: string;

    constructor(clientApplication?: string, agentId?: number, token?: string) {

        this.clientApplication = clientApplication;
        this.agentId = agentId;
        this.token = token;
    }

    
}