export class SectionField {
    public Id: string;
    public Name: string;
    public Description: string;
    public FileName: string;
    public Value: string;
    public Mandatory: boolean;
    public DataType: string;

    public constructor(field?) {

        if (field) {
            this.Id = field.Id;
            this.Name = field.Name;
            this.Description = field.Description;
            this.FileName = field.FileName;
            this.Value = field.Value;
            this.Mandatory = field.Mandatory;
            this.DataType = field.DataType;
        }
    };
}