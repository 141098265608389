import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ResetPasswordService } from '../../Services/resetPassword.service';
import { SessionService } from '../../Services/Session.service';
import { LoginModel } from '../../Models/Login.model';
import { DriverOnboardingService } from '../../Services/DriverOnboarding.service';
import { ErrorMessage } from '../../Models/Error.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-resetPassword',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

    @ViewChild("contactemail") contactEmail: ElementRef;

    public model = new LoginModel();
    public resetPasswordLinkSent = false;
    public errorMessage = null;
    public BackgroundColours = ["#ff0000" /* error */, "#EFA136", "#F47941", "#B22E40", "#05858C", "#323742" /* message */, "#50BC89", "#33B6AE", "#2A5AA6" /* login */, "#FFD13A"];

    constructor(
        private sessionService: SessionService,
        private resetPasswordService: ResetPasswordService,
        private driverOnbordingService: DriverOnboardingService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private appComponent: AppComponent) {

    }

    ngOnInit() {

    }

    public sendResetPasswordLink() {

        this.errorMessage = null;
        this.resetPasswordLinkSent = false;

        if (this.sessionService.agentId <= 0) {

            this.errorMessage = new ErrorMessage();
            this.errorMessage.IsMessage = true;

            this.translate.get('NotEnabled').subscribe((res: string) => {

                this.errorMessage.Summary = res;

                this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                    this.errorMessage.Details = res;
                });
            });
        }
        else {

            this.spinner.show();

            this.driverOnbordingService.IsDriverOnboardingEnabled().subscribe((result: any) => {

                this.spinner.hide();

                if (result.IsDriverOnboardingEnabledResult.Info.Status == "SUCCESS" && result.IsDriverOnboardingEnabledResult.Content) {

                    this.validateBeforeSendingResetLink();
                }
                else {

                    this.errorMessage = new ErrorMessage();
                    this.errorMessage.IsMessage = true;

                    this.translate.get('NotEnabled').subscribe((res: string) => {

                        this.errorMessage.Summary = res;

                        this.translate.get('FeatureNotEnabled').subscribe((res: string) => {

                            this.errorMessage.Details = res;
                        });
                    });
                }
            });
        }
    }

    public validateBeforeSendingResetLink() {

        if (!this.validateEmail()) {

            this.contactEmail.nativeElement.select();
        }
        else {

            this.doSendResetPasswordLink();
        }
    }

    public focusIn() {

    }

    public focusOut() {

    }

    public login() {

        this.resetPasswordLinkSent = false;
        this.appComponent.ShowLoginView();
    }

    public register() {

        this.resetPasswordLinkSent = false;
        this.appComponent.ShowRegisterView();
    }

    private doSendResetPasswordLink() {

        this.spinner.show();
        this.contactEmail.nativeElement.select();

        this.resetPasswordService.SendResetPasswordLink(this.model.username).subscribe((result: any) => {

            this.spinner.hide();
            
            if (result.SendResetPasswordLinkResult.Info.Status == "SUCCESS" && result.SendResetPasswordLinkResult.Content) {

                this.resetPasswordLinkSent = true;
            }
            else {
                this.errorMessage = new ErrorMessage();

                if (result.SendResetPasswordLinkResult.Info.Messages.length) {

                    this.errorMessage.Details = result.SendResetPasswordLinkResult.Info.Messages[0].Summary;
                }
                else {

                    this.translate.get('RegisterFailed').subscribe((res: string) => {
                        this.errorMessage.Details = res;
                    });
                }
            }
        });
    }

    private validateEmail() {

        var valid = false;

        if (this.model.username) {

            var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            valid = pattern.test(this.model.username);
        }

        return valid;
    }
}
