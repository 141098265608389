import { Injectable, OnInit } from "@angular/core";
import { DriverOnboardingService } from "./DriverOnboarding.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Settings } from "../Models/Settings.model";

@Injectable()
export class SettingsService implements OnInit {

    private defaultCulture = 'en-GB';
    private settings = new Settings();
    private cultures: Array<string> =
        [
            'ar-IQ',
            'bg-BG',
            'de-DE',
            'en-GB',
            'es-ES',
            'fr-FR',
            'nl-NL',
            'pt-PT',
            'ru-RU',
            'sr-RS',
            'sw-KE',
            'zh-SG',
            'uk-UA',
            'sr-Latn-RS',
            'pt-BR',
        ];

    constructor(
        private driverOnbordingService: DriverOnboardingService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService) {
    }

    ngOnInit() {

    }

    public getSettings() {

        this.spinner.show();

        this.driverOnbordingService.GetSettings().subscribe((result: any) => {

            if (result.GetSettingsResult.Info.Status == "SUCCESS" && result.GetSettingsResult.Content) {

                this.settings = new Settings(result.GetSettingsResult.Content);
            }

            this.setCulture();
        });
    }

    private setCulture() {

        var culture = this.getCulture();
        this.translate.setDefaultLang(culture);
        this.translate.use(culture);
    }

    private getCulture() {

        var culture = this.defaultCulture;

        if (this.settings.culture) {

            if (this.cultures.indexOf(this.settings.culture) > -1) {

                culture = this.settings.culture;
            }
            else {

                for (var i = 0; i < this.cultures.length; i++) {

                    var lang1 = this.settings.culture.substring(0, this.settings.culture.indexOf('-'));
                    var lang2 = this.cultures[i].substring(0, this.cultures[i].indexOf('-'));

                    if (lang1 == lang2) {
                        culture = this.cultures[i];
                        break;
                    }
                }
            }
        }

        return culture;
    }
}