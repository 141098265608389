import { Injectable } from '@angular/core';
import { RepositoryService } from './Repository.service';
import { EndpointService } from './Endpoint.service';
import { SessionService } from './Session.service';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private repository: RepositoryService, private session: SessionService) { }

    public SendResetPasswordLink(email: string) {

        let payload = {
            clientApplication: this.session.sessionData.clientApplication,
            agentId: this.session.sessionData.agentId,
            email: email
        };

        return this.repository.post(EndpointService.SendResetPasswordLink, payload);
    }   
}
