import { Component, OnInit } from '@angular/core';
import { DriverOnboardingService } from '../Services/DriverOnboarding.service';
import { Section } from '../Models/Section.model';
import { ErrorMessage } from '../Models/Error.model';
import { SessionService } from '../Services/Session.service';
import { Router, RoutesRecognized } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from '../Services/Settings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    public Sections: Section[] = [];
    public ErrorMessage = null;
    public isLoggedIn = true;
    public isRegistering = false;
    public isFirstSection = false;
    public isResetingPassword = true;

    constructor(
        private driverOnbordingService: DriverOnboardingService,
        private session: SessionService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private settings: SettingsService,
        private translate: TranslateService) {

    }

    ngOnInit() {

        this.router.events.subscribe(event => {

            if (event instanceof RoutesRecognized) {

                this.session.agentId = event.state.root.firstChild && event.state.root.firstChild.params ? event.state.root.firstChild.params['agentId'] : 0;
                this.session.GetSessionToken();
                this.session.SetSessionAgentId(this.session.agentId);
                this.settings.getSettings();

                if (!this.session.sessionData || !this.session.sessionData.token) {

                    this.isLoggedIn = false;
                    this.spinner.hide();

                    if (this.session.sessionData.agentId <= 0) {

                        this.isFirstSection = true;
                        this.ErrorMessage = new ErrorMessage();
                        this.ErrorMessage.IsMessage = true;
                        this.ErrorMessage.HideBackLink = true;

                        setTimeout(() => {

                            this.translate.get("NotEnabled").subscribe((res: string) => {

                                this.ErrorMessage.Summary = res;

                                this.translate.get("FeatureNotEnabled").subscribe((res: string) => {

                                    this.ErrorMessage.Details = res;
                                });
                            });
                        }, 1200);

                    }
                    else if (!this.session.sessionData.token) {

                        this.isFirstSection = true;
                        this.GetSections();
                    }
                }
                else {

                    this.isLoggedIn = true;
                    this.GetSections();
                }
            }
        });

        this.driverOnbordingService.OnGetApplication().subscribe(() => {

            this.spinner.hide();
            this.isLoggedIn = !this.isFirstSection;
            this.GetSections();
        });
    };

    public ShowLoginView() {

        this.isFirstSection = false;
        this.isLoggedIn = false;
        this.isRegistering = false;
        this.isResetingPassword = false;
    }

    public ShowRegisterView() {

        this.isFirstSection = false;
        this.isLoggedIn = false; 
        this.isRegistering = true;
        this.isResetingPassword = false;
    }

    public ShowResetPasswordView() {

        this.isFirstSection = false;
        this.isLoggedIn = false;
        this.isRegistering = false;
        this.isResetingPassword = true;
    }

    private GetSections() {

        this.spinner.show();

        this.driverOnbordingService.GetApplication(true).subscribe((result: any) => {

            this.spinner.hide();

            if (result.GetApplicationResult.Info.Status == "SUCCESS" || result.GetApplicationResult.Info.Status == "WARNING") {

                this.Sections = result.GetApplicationResult.Content ? result.GetApplicationResult.Content.map(section => {
                    return new Section(section);
                }) : [];

                if (result.GetApplicationResult.Info.Status == "WARNING") {

                    if (result.GetApplicationResult.Info.Messages.length) {

                        var message = result.GetApplicationResult.Info.Messages[0];

                        if (message.Code == "4") {

                            this.translate.get("ApplicationChangedSummary").subscribe((res: string) => {

                                var summary = res;

                                this.translate.get("ApplicationChangedDetails").subscribe((res: string) => {

                                    var details = res;

                                    this.ErrorMessage = new ErrorMessage();
                                    this.ErrorMessage.Summary = summary;
                                    this.ErrorMessage.Details = details;
                                    this.ErrorMessage.StartAgain = true;
                                    this.ErrorMessage.IsMessage = true;
                                });
                            });
                        }
                        else {

                            this.ErrorMessage = new ErrorMessage(result.GetApplicationResult.Info.Messages.length ? result.GetApplicationResult.Info.Messages[0] : null, true, true);
                        }
                    }
                    else {

                        this.ErrorMessage = new ErrorMessage(null, true, true);
                    }
                }
            }
            else {

                this.Sections = [];

                if (result.GetApplicationResult.Info.Messages.length && result.GetApplicationResult.Info.Messages[0].Code == "3") {

                    this.ErrorMessage = new ErrorMessage();
                    this.ErrorMessage.IsMessage = true;
                    this.ErrorMessage.HideBackLink = true;

                    this.translate.get("NotEnabled").subscribe((res: string) => {

                        this.ErrorMessage.Summary = res;

                        this.translate.get("FeatureNotEnabled").subscribe((res: string) => {

                            this.ErrorMessage.Details = res;
                        });
                    });
                }
                else {

                    this.ErrorMessage = new ErrorMessage(result.GetApplicationResult.Info.Messages.length ? result.GetApplicationResult.Info.Messages[0] : null);
                }
            }
        });
    }
}
